import axios from "axios";

const apiBaseUrl =
  process.env.REACT_APP_CASHTAG_API_URL ||
  "https://cashtag-api.lesscoresdechantal.com/v1";

const API = {
  listCashtags() {
    return axios.get(`${apiBaseUrl}/cashtags`);
  },

  getCashtagChart(cashtag) {
    return axios.get(`${apiBaseUrl}/cashtags/${cashtag}`);
  },

  getCashtagHourlyChart(cashtag) {
    return axios.get(`${apiBaseUrl}/cashtags/hourly/${cashtag}`);
  },

  getCashtagMiscData(cashtag) {
    return axios.get(`${apiBaseUrl}/cashtags/${cashtag}/misc`);
  },

  getCashtagHourlyMiscData(cashtag) {
    return axios.get(`${apiBaseUrl}/cashtags/hourly/${cashtag}/misc`);
  },
};

export default API;
