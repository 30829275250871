import React from "react";

const Supply = () => {
  return (
    <div>
      <h1>Supply</h1>
    </div>
  );
};

export default Supply;
