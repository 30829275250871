import React from "react";
import CashtagsCharts from "../components/CashtagsCharts";

const Cashtags = () => {
  return (
    <div>
      <h1>Cashtag Charts</h1>
      <CashtagsCharts />
    </div>
  );
};

export default Cashtags;
