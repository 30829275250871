import React from "react";
import CashtagsHourlyCharts from "../components/CashtagsChartsHourly";

const CashtagsHourly = () => {
  return (
    <div>
      <h1>Cashtags Hourly Charts</h1>
      <CashtagsHourlyCharts />
    </div>
  );
};

export default CashtagsHourly;
