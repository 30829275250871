import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Cashtags from "./pages/cashtags";
import CashtagsHourly from "./pages/cashtagsHourly";
import Supply from "./pages/supply";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="/cashtags-hourly" />} />
          <Route path="/cashtags-hourly" element={<CashtagsHourly />} />
          <Route path="/cashtags-daily" element={<Cashtags />} />
          <Route path="/supply" element={<Supply />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
