import React, { useMemo, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const Chart = ({ cashtag, cashtagCharts, pricesCharts }) => {
  const buildChartsOptions = (c, cashtagCharts, pricesCharts) => {
    const yAxis = [
      {
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Cashtags",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
    ];

    for (let i = 0; i < pricesCharts.length; i++) {
      yAxis.push({
        title: {
          text: pricesCharts[i].name,
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      });
      pricesCharts[i].yAxis = i + 1;
    }

    return {
      title: { text: `Chart ${c ? c : ""}` },
      legend: { enabled: true },
      chart: { height: "35%", zoomType: "x" },
      yAxis,
      accessibility: {
        enabled: false,
      },
      series: [...cashtagCharts, ...pricesCharts],
    };
  };

  const [options, setOptions] = useState(buildChartsOptions("", [], []));

  useMemo(() => {
    setOptions(buildChartsOptions(cashtag, cashtagCharts, pricesCharts));
  }, [cashtag]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};

export default Chart;
