import React, { useState, useEffect } from "react";
import API from "../../api";
import CashtagChart from "./Chart";
import CashtagSelector from "./CashtagSelector";

const CashtagsCharts = () => {
  const [cashtags, setCashtags] = useState([]);
  const [pricesCharts, setPricesCharts] = useState([]);
  const [coins, setCoins] = useState([]);
  const [selectedCashtag, setSelectedCashtag] = useState([]);
  const [selectedCashtagCharts, setSelectedCashtagCharts] = useState([]);

  useEffect(() => {
    const fetchCashtags = async () => {
      try {
        const response = await API.listCashtags();
        const cashtags = response.data;
        setCashtags(cashtags);
      } catch (err) {
        console.error(err);
      }
    };

    fetchCashtags();
  }, []);

  const handleChange = async (option) => {
    const response = await API.getCashtagChart(option.value);
    const responseMisc = await API.getCashtagMiscData(option.value);

    const chart = response.data
      ?.map((elem) => [Date.parse(elem.date), elem.count])
      ?.sort((a, b) => a[0] - b[0]);

    const relatedCoins = responseMisc.data;
    relatedCoins.map((elem) => elem?.prices?.sort((a, b) => a[0] - b[0]));

    setCoins(
      relatedCoins.map((coin) => ({
        name: coin?.name,
        symbol: coin?.symbol?.toUpperCase(),
        price: coin?.price,
        marketcap: coin?.marketcap,
        cgId: coin?._id?.split("@")[0],
      }))
    );

    const cashtag = option.value;
    setSelectedCashtagCharts([
      {
        name: cashtag,
        data: chart,
        lineWidth: 3,
        zIndex: 1,
      },
      {
        name: `${cashtag} mm3d`,
        data: calculateMovingAverage(chart, 3),
        visible: false,
      },
      {
        name: `${cashtag} mm5d`,
        data: calculateMovingAverage(chart, 5),
        visible: false,
      },
      {
        name: `${cashtag} mm7d`,
        data: calculateMovingAverage(chart, 7),
        visible: false,
      },
    ]);
    setSelectedCashtag(cashtag);

    setPricesCharts(
      relatedCoins.map((elem) => ({
        name: `${elem.name} Price USD`,
        data: alignCharts(elem.prices, chart),
        color: "#000000",
      }))
    );
  };

  const alignCharts = (chartToAlign, baseChart) => {
    const chartToAlignStart = chartToAlign[0][0];
    const baseChartStart = baseChart[0][0];
    if (chartToAlignStart < baseChartStart) {
      return chartToAlign.filter((item) => item[0] >= baseChartStart);
    }
    return chartToAlign;
  };

  const calculateMovingAverage = (data, days) => {
    let result = [];

    for (let i = 0; i < data.length; i++) {
      if (i >= days) {
        let sum = 0;
        for (let j = 0; j < days; j++) {
          sum += data[i - j][1];
        }
        result.push([data[i][0], Math.round((sum / days) * 100) / 100]);
      }
    }

    return result;
  };

  return (
    <div className="cashtag-wrapper">
      <div className="cashtag-selector">
        <CashtagSelector cashtags={cashtags} handleChange={handleChange} />
        <div>
          <table
            style={{
              "margin-top": "10px",
              width: "100%",
              border: "1px solid rgb(204, 204, 204)",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <th>Name</th>
                <th>Symbol</th>
                <th>Price (USD)</th>
                <th>Market Cap (USD)</th>
                <th>Coingecko URL</th>
              </tr>
            </thead>
            <tbody>
              {coins.map((coin, index) => (
                <tr key={index}>
                  <td>{coin.name}</td>
                  <td>{coin.symbol}</td>
                  <td>{coin.price}</td>
                  <td>{coin.marketcap}</td>
                  <td>
                    <a
                      href={"https://www.coingecko.com/en/coins/" + coin.cgId}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {coin.cgId}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cashtag-chart">
        <CashtagChart
          cashtag={selectedCashtag}
          cashtagCharts={selectedCashtagCharts}
          pricesCharts={pricesCharts}
        />
      </div>
    </div>
  );
};

export default CashtagsCharts;
