import React, { useState, useMemo } from "react";
import Select from "react-select";

const CashtagSelector = ({ cashtags, handleChange }) => {
  const maxOptionsSize = 150;
  const cashtagsToOptions = (cashtags) => {
    if (cashtags && Array.isArray(cashtags))
      return cashtags
        .map((cashtag) => ({ value: cashtag, label: cashtag }))
        .slice(0, maxOptionsSize);
    return [];
  };

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useMemo(() => {
    if (inputValue.length >= 2) {
      setOptions(
        cashtagsToOptions(
          cashtags.filter((cashtag) =>
            cashtag.toLowerCase().includes(inputValue.toLocaleLowerCase())
          )
        )
      );
    } else {
      setOptions(cashtagsToOptions(cashtags));
    }
  }, [inputValue, cashtags]);

  return (
    <Select
      options={options}
      onChange={handleChange}
      onInputChange={setInputValue}
      isSearchable
      isClearable
      placeholder="Select or type a cashtag..."
    />
  );
};

export default CashtagSelector;
